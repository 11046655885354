export const codeObj = {
  110001: "用户名或密码错误，请重新输入",
  110002: "账号不可用",
  100206: "用户名或密码错误，请重新输入",
  110102: "验证码不正确",
  110101: "验证码不正确",
  120001: '用户不存在',
  120002: '用户已存在',
  120003: '密码格式不正确',
  120004: '密码不正确',
  130001: '机制名称已存在',
  130002: '机制在使用中',
  130003: '机制不存在',
  130101: '产品已存在',
  130102: '产品未找到',
  130103: '产品地址未找到',
  130104: '产品地址错误',
  130105: '产品状态有误',
  130106: '产品不可删除',
  130201: '活动未找到',
  130202: '活动状态有误',
  130109: '产品编号已存在',
  140022: '注销证明已上传',
  100203: 'Token过期，请重新登录',
};
