<template>
  <div class="home">
    <div class="header">
      <img src="@/assets/jtkjlogo.png" alt="" />
      <div>
        {{ roleObj[userInfo.role] }} {{ userInfo.username }}
        <div class="user-message" @click="logout">退出</div>
      </div>
    </div>
    <div class="content">
      <div class="nav">
        <div
          :class="['nav-list', { active: url == '/home/dailyNews' }]"
          @click="toPage('/home/dailyNews')"
        >
          <img src="@/assets/images/co2.png" alt="" srcset="" />
          碳减排量产品
        </div>
        <div
          :class="['nav-list', { active: url == '/home/carbonList' }]"
          @click="toPage('/home/carbonList')"
        >
          <img src="@/assets/images/xiangmuguanli.png" alt="" srcset="" />
          碳中和活动
        </div>
        <div
          :class="['nav-list', { active: url == '/home/carbonCheck' }]"
          @click="toPage('/home/carbonCheck')"
        >
          <img src="@/assets/images/dingdan.png" alt="" srcset="" />
          客户订单
          <div class="order">
            <p>
              <el-icon style="margin-right:3px"><WarningFilled /></el-icon>{{ orderObj.order_cancel_upload }}
            </p>
            <p>
              <el-icon style="margin-right:3px"><BellFilled /></el-icon>{{ orderObj.order_invoice_upload }}
            </p>
          </div>
        </div>
        <div
          :class="['nav-list', { active: url == '/home/roleUser' }]"
          @click="toPage('/home/roleUser')"
        >
          <img src="@/assets/images/guanliyuan.png" alt="" srcset="" />
          角色管理
        </div>
        <div
          :class="['nav-list', { active: url == '/home/personalCenter' }]"
          @click="toPage('/home/personalCenter')"
        >
          <img src="@/assets/images/user.png" alt="" srcset="" />
          账号信息
        </div>
      </div>
      <div class="section">
        <router-view />
      </div>
    </div>
  </div>
</template>   

<script>
import { mapState, mapMutations } from "vuex";
import {order_status_count} from "@/api/carboncheck.js";
export default {
  name: "HomeView",
  data() {
    return {
      roleObj: { 2: "管理员", 4: "超级管理员" },
    };
  },
  created() {
    let isLogin =
      localStorage.getItem("userInfo") && localStorage.getItem("token");
    if (!isLogin) {
      this.$router.push("/loginPage");
    }
  },
  mounted() {
    this.getOrderCount()
  },
  computed: {
    ...mapState("login", ["token", "userInfo", "orderObj"]),
    url() {
      return this.$route.path;
    },
  },
  methods: {
    ...mapMutations("login", ["SET_ORDER_OBJ"]),
    toPage(path) {
      this.url = path;
      this.$router.push(path);
    },
    logout() {
      localStorage.removeItem("token");
      this.$router.push("/loginPage");
    },
    getOrderCount() {
      order_status_count().then((res) => {
        if (res.code == 0) {
          this.SET_ORDER_OBJ(res.data)
        }
      })
    }
  },
};
</script>
<style lang="less" scoped>
.home {
  height: 100%;
  min-width: 1000px;
  .header {
    height: 80px;
    background: #383953;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    > img {
      height: 50%;
    }
    > div {
      display: flex;
      align-items: center;
      color: #ffffff;
    }
    .user-message {
      cursor: pointer;
      margin-left: 20px;
    }
  }
  .content {
    height: calc(100% - 80px);
    .nav {
      height: 100%;
      width: 240px;
      background: #303142;
      float: left;
      .nav-list {
        height: 50px;
        line-height: 50px;
        display: flex;
        align-items: center;
        justify-content: left;
        color: #ffffff;
        font-size: 16px;
        text-align: center;
        cursor: pointer;
        border-bottom: 1px solid #404152;
        padding-left: 18%;
        > img {
          margin-right: 5px;
          width: 20px;
          height: 20px;
        }
        > div {
          height: 50px;
          padding-left: 6px;
          padding-top: 5px;
          > p {
            margin: 0;
            height: 22px;
            line-height: 22px;
            font-size: 15px;
            color: #FFD39B;
            display: flex;
            align-items: center;
          }
        }
      }
      .active {
        background: #2578bf;
      }
      .nav-list:hover {
        background: #3883c1;
        font-size: 18px;
      }
    }
    .section {
      height: 100%;
      padding: 24px;
      box-sizing: border-box;
      background: #eff4f9;
      margin-left: 240px;
    }
  }
}
</style>
