import { httpGet, httpPost } from "./http";
let pre;
if (process.env.NODE_ENV == "development") {
  pre = "/api";
} else {
  pre = "";
}

// 订单列表
export const order_list = (params) =>
httpGet({ url: `${pre}/htsc/order_service/admin/order_list?${params}` });
// 凭证上传
export const order_upload = (params) =>
httpPost({ url: `${pre}/htsc/order_service/admin/order_upload`, params });
// 发票上传
export const order_invoice_upload = (params) =>
httpPost({ url: `${pre}/htsc/order_service/admin/order_invoice_upload`, params });
// 补发邮件
export const order_resend_email = (params) =>
httpPost({ url: `${pre}/htsc/order_service/admin/order_resend_email`, params });
//发票详情
export const order_invoice_info = (params) =>
httpGet({ url: `${pre}/htsc/order_service/admin/order_invoice_info?id=${params.id}` });
//注销原因
export const cancel_reason_list = () =>
httpGet({ url: `${pre}/htsc/order_service/common/cancel_reason_list` });
//订单详情
export const order_info = (params) =>
httpGet({ url: `${pre}/htsc/order_service/admin/order_info?id=${params.id}` });
//生成注销证明

export const order_cancel_proof = (params) =>
httpPost({ url: `${pre}/htsc/order_service/admin/order_cancel_proof`,params });
//待处理订单数
export const order_status_count = (params) =>
httpGet({ url: `${pre}/htsc/order_service/admin/order_status_count`,params });
//取消订单
export const order_cancel = (params) =>
httpPost({ url: `${pre}/htsc/order_service/admin/order_cancel`,params });

