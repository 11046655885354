import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    redirect: "/loginPage",
  },
  {
    path: "/loginPage",
    name: "LoginPage",
    component: () => import("../views/LoginPage.vue"),
  },
  {
    path: "/viewPdf",
    name: "viewPdf",
    component: () => import("../views/viewPdf.vue"),
  },
  {
    path: "/home",
    name: "home",
    component: HomeView,
    children: [
      {
        path: "dailyNews",
        name: "DailyNews",
        component: () => import("../views/DailyNews/DailyNews.vue"),
      },
      {
        path: "carbonList",
        name: "CarbonList",
        component: () => import("../views/CarbonList/CarbonList.vue"),
      },
      {
        path: "carbonCheck",
        name: "CarbonCheck",
        component: () => import("../views/CarbonCheck/CarbonCheck.vue"),
      },
      {
        path: "roleUser",
        name: "RoleUser",
        component: () => import("../views/RoleUser/RoleUser.vue"),
      },
      {
        path: "personalCenter",
        name: "PersonalCenter",
        component: () => import("../views/PersonalCenter/PersonalCenter.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  // 设置页面标题
  let path = to.path
  document.title = path == '/viewPdf' ? '' : "惠碳商城后台管理系统";
  next()
});

export default router;
