import axios from "axios";
import router from "../router";
import { ElMessage } from "element-plus";
import { codeObj } from "@/utils/errorCode";
// 请求拦截器
axios.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断vuex中是否存在token
    // 如果存在，则统一在http请求的header都加上token，这样后台根据token判断你的登录情况
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    const token = localStorage.getItem("token");
    token && (config.headers.Authorization = token);
    return config;
  },
  (error) => {
    return Promise.error(error);
  }
);
// 响应拦截器
axios.interceptors.response.use(
  (response) => {
    // 如果返回的状态码为200，说明接口请求成功，可以正常拿到数据
    // 否则的话抛出错误
    if (response && response.status === 200) {
      let code = response.data.code;
      if (code != 0) {
        if (codeObj[code]) {
          ElMessage.error(codeObj[code]);
        }else{
          ElMessage.error("请求失败，请联系管理员");
        }
      }
      if (response.data.code == 401 || response.data.code == 410 || response.data.code == 100203) {
        router.push("/loginPage");
      }
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  },
  // 服务器状态码不是2开头的的情况
  (error) => {
    if (error.response?.status) {
      switch (error.response.status) {
        // 401: 未登录
        // 未登录则跳转登录页面，并携带当前页面的路径
        // 在登录成功后返回当前页面，这一步需要在登录页操作。
        case 401:
          break;
        // 403 token过期
        // 登录过期对用户进行提示
        // 清除本地token和清空vuex中token对象
        // 跳转登录页面
        case 403:
          break;
        // 404请求不存在
        case 404:
          break;
        // 其他错误，直接抛出错误提示
        default:
          console.log(error.response.data.message);
      }
      return Promise.reject(error);
    }
  }
);

// get 请求
export function httpGet({ url, params = {} }) {
  try {
    return new Promise((resolve, reject) => {
      axios
        .get(url, {
          params,
        })
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  } catch (error) {
    console.log(error);
  }
}

// post请求
export function httpPost({ url, params }) {
  try {
    return new Promise((resolve, reject) => {
      axios
        .post(url, params)
        .then((res) => {
          if (!res) {
            return;
          }
          resolve(res.data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  } catch (error) {
    console.log(error);
  }
}
