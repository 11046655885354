export const login = {
    namespaced: true,
    state: {
      token: localStorage.getItem('token'),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      orderObj:{order_cancel_upload:0, order_invoice_upload: 0}
    },
    getters: {
      token: state => () => {
        return state.token;
      },
      userInfo: state => () => {
        return state.userInfo;
      },
    },
    mutations: {
      upateToken(state, token) {
        state.token = token;
      },
      upateUserInfo(state, userInfo) {
        state.userInfo = userInfo;
      },
      SET_ORDER_OBJ(state, orderObj) {
        console.log(orderObj)
        state.orderObj = orderObj;
      }
    },
  };
  